// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
.ant-notification {
  top: 115px !important;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: #717479;
  
}
.c-sidebar-nav {
  background: #757a82;
  font-weight: 600px;

}
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
  background: transparent;
  font-weight: 700;
}
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #64686E;

}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: #64686E;
}
.c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 21, 0.2);
  display: none;
}
.ant-table-tbody > tr > td {
  /* border-bottom: 1px solid #f0f0f0; */
  border: 0 !important;
}
.ant-table-thead{
  background-color: red !important;
}
.ant-tabs-tab .ant-tabs-tab-active {
  color: #B88914 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #B88914 !important;
}
.ant-tabs-ink-bar {
  height: 5px;
  background: #B88914 !important;
}
.ant-pagination-item-active a {
  color: #F4F5F7 !important;
}

.ant-pagination-item-active {
  font-weight: 500 ;
  border-color: #252E58 !important;
  background: #252E58 !important;
}